<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Asset Categories</span>
          <v-spacer></v-spacer>
          <v-flex xl3 lg3 md3 pb-2 sm3 xs12 py-2 py-sm-0>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="40%"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="font-family: kumbhBold"
                  dark
                  block
                  class="rounded-xl"
                  color="#005f32"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Category
                </v-btn>
              </template>
              <v-card>
                <v-form v-model="addcat" ref="addcat">
                  <v-layout wrap>
                    <v-flex
                      xs12
                      pa-4
                      text-left
                      style="background: #005f32 !important"
                    >
                      <span
                        class="kumbhBold"
                        style="color: #ffffff; font-size: 20px"
                        >Add Category</span
                      >
                    </v-flex>
                    
                    <v-flex xs12 align-self-center text-left px-5 pa-2 pt-8>
                      <v-text-field
                        v-model="name"
                        label="Category Name"
                        :rules="[rules.required]"
                        required
                        autofocus
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 align-self-center text-left px-5 pa-2 >
                      <v-text-field
                        v-model="code"
                        label="Category Code"
                        :rules="[rules.required]"
                        required
                        outlined
                        dense 
                        persistent-hint
                        hint="NB: This code could not be editable after saving."
                      ></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      color="error"
                      tile
                      outlined
                      @click="dialogclose(), (name = null)"
                    >
                      Close
                    </v-btn>
                    &nbsp;
                    <v-btn
                      color="#005f32"
                      dark
                      tile
                      :disabled="!addcat"
                      @click="addCategory()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start v-if="categoryList.length > 0">
          <v-flex
            xs12
            md6
            sm6
            lg4
            xl3
            align-self-center
            v-for="(item, i) in categoryList"
            :key="i"
            fill-height
            pa-4
          >
            <v-card tile color="#F5F5DC">
              <v-layout wrap justify-end>
                <v-flex xs12 align-self-start text-center pa-4>
                  <span class="lheight" style="font-family: kumbhBold"
                    >{{ item.name }}
                    <span v-if="item.code" style="font-family: kumbhBold">
                      ({{ item.code }})</span
                    ></span
                  >
                </v-flex>
                <v-flex xs12 align-self-center>
                  <v-divider></v-divider>
                </v-flex>

                <v-flex xs6 sm4 align-self-center md2 pa-2>
                  <v-dialog persistent v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="font-family: kumbhBold"
                        class="mr-2"
                        fab
                        outlined
                        depressed
                        dark
                        title="Delete"
                        v-on="on"
                        v-bind="attrs"
                        color="#005f32"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          align-self-center
                          pa-4
                          style="background: #005f32"
                          text-left
                        >
                          <span class="kumbhBold" style="color: #ffffff"
                            >Delete Confirmation</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-card-title
                        >Are you sure you want to delete this Asset
                        category?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          tile
                          dark
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        &nbsp;
                        <v-btn
                          color="#005f32"
                          outlined
                          tile
                          @click="deleteItem(item)"
                          >Delete</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs6 sm4 md2 pa-2>
                  <v-btn
                    color="#005f32"
                    style="font-family: kumbhBold"
                    small
                    dark
                    outlined
                    title="Edit"
                    depressed
                    fab
                    @click="editcat(item)"
                  >
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Categories found!</span>
          </v-flex>
        </v-layout>

        <v-dialog persistent v-model="editdialog" max-width="40%">
          <v-card tile>
            <v-layout wrap>
              <v-flex
                xs12
                pa-4
                text-left
                align-self-center
                style="background: #005f32 !important"
              >
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px"
                  >Edit Category</span
                >
              </v-flex>

              <v-flex xs12 align-self-center text-left px-5 pa-2 pt-8>
                <v-text-field
                  v-model="editingitem.code"
                  label="Category Code"
                  :rules="[rules.required]"
                  required
                  outlined
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-5 pa-2 text-left>
                <v-text-field
                  v-model="editingitem.name"
                  label="Category Name"
                  :rules="[rules.required]"
                  outlined
                  autofocus
                  dense
                  required
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                tile
                outlined
                @click="(editdialog = false), getData()"
              >
                Close
              </v-btn>
              &nbsp;
              <v-btn
                v-if="!g"
                color="#005f32"
                dark
                tile
                @click="edit(editingitem._id)"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      categoryList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      code: null,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/assetCategory/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryList = response.data.data;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addCategory() {
      var data = {};
      data["name"] = this.name;
      data["code"] = this.code;
      axios({
        url: "/assetCategory/add/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.code = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["name"] = this.editingitem.name;
      data["code"] = this.editingitem.code;
      axios({
        url: "/assetCategory/add/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.getData();
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
